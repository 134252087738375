.download-links {
    a {
        display: block;
        max-width: 220px;
        width: 48%;
        margin-right: 1%;

        img {
            max-width: 100%;
        }
    }
}
