.modal {

    &.modal-alert {
        .modal-header {
            border: none;
            h1, h2, h3 {
                margin-top: $padding-s;
                color: $body-color;
            }
        }
        .modal-body {
            padding-top: 0;
            @include font-size(15);
        }
        .modal-footer {
            border: none;
        }

        &.modal-danger {
            h1, h2, h3 {
                color: $alert-color;
            }
        }
    }

    .loading-indicator {
        margin: $padding-xl auto;
    }

    &.modal-scroll {
        overflow-x: hidden;
        overflow-y: auto;

        .modal-content {
            padding: $padding-s $padding-m;
        }

        .modal-header {
            border: none;

            h1, h2, h3 {
                @include font-size(28);
            }

            .btn-icon {
                padding: 0;
                margin: 0;
            }
        }
    }

}

@include media-breakpoint-down(xl) {
    .modal-xxl {
        margin: 1%;
        width: 98%;
        max-width: 98%;
    }
}

@include media-breakpoint-up(xl) {
    .modal-xl {
        width: 70%;
        max-width: 70%;
    }
    .modal-xxl {
        width: 90%;
        max-width: 90%;
    }
}
