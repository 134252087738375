.form-search {
    input {
        appearance: textfield;
        padding: $padding-s $padding-l;
        border-radius: 22px;
        background: rgba(#fff, 0.15);
        color: $white;

        &:focus {
            color: $white;
            background: rgba(#fff, 0.15);
        }

        &::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: #eee !important;
            opacity: 0.5 !important;
            font-style: italic;
        }
        &:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #eee !important;
            opacity: 0.5 !important;
            font-style: italic;
        }
        &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #eee !important;
            opacity: 0.5 !important;
            font-style: italic;
        }
        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #eee !important;
            opacity: 0.5 !important;
            font-style: italic;
        }
    }

    .dropdown {
        position: relative;
    }

    .dropdown-menu.show {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        top: 85%;
        width: calc(100% - #{$dropdown-mobile-margin});
        right: $dropdown-mobile-margin / 2;
        left: $dropdown-mobile-margin / 2;

        .dropdown-item {
            cursor: pointer;
            & + .dropdown-item {
                border-top: 1px solid $border-color;
            }
        }
    }
}
