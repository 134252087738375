.progressbar {
    position: relative;
    border-radius: 32px;
    height: 10px;
    width: 100%;
    background-color: #eeeeee;
    overflow: hidden;

    .inner {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: $primary;

        &.bg-primary {
            background-color: $primary;
        }

        &.bg-secondary {
            background-color: $secondary;
        }

        &.bg-tertiary {
            background-color: $tertiary;
        }

        &.bg-alert {
            background-color: $alert-color;
        }
    }
}
