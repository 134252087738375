.onboarding-split {
    height: 100vh;
    display: flex;

    .onboarding-image {
        position: fixed;
        height: 100%;
        width: 100%;
        background: #fafafa;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url('../../assets/bg-home.jpg');
    }

    .onboarding-content {
        overflow-y: auto;

        > * {
            margin: auto;
        }

        img {
            display: block;
            margin: 0 auto $padding-l auto;
        }

        padding: $padding-m $padding-sm;
        .container {
            padding: 0;
            max-width: $card-max-width;
        }

        .card {
            .card-body {
                padding: $padding-xl;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .onboarding-split {
        > * {
            width: 50%;
            height: 100vh;
        }

        .onboarding-image {
            height: 100%;
            width: 50%;
            position: relative;
        }
        .onboarding-content {
            padding: $padding-l $padding-l;
            .container {
                padding: 0 15px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .onboarding-split {
        .onboarding-content {
            padding: $padding-xl $padding-xl;
        }
    }
}
