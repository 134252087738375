.alert-header {
    position: fixed;
    bottom: 0;
    right: $padding-m;
    background: $transparent-alert-color;
    color: $white;
    font-weight: $font-weight-semi-bold;
    border-radius: 5px 5px 0 0;
    padding: $padding-xs $padding-s;
    @include font-size(12);

    &:hover {
        text-decoration: none;
    }
}
