.DayPickerInput {
    display: block !important;
    input {
        @extend .form-control;
    }
}

.is-invalid .DayPickerInput {
    input {
        border-color: $alert-color;
    }
}

.DayPicker-Day {
    padding: 0.4rem 0.55rem;
    color: $body-color;
}

.DayPicker-Day--today {
    color: $primary-dark;
}

.DayPicker-Day--disabled {
    opacity: 0.3;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    color: #f0f8ff;
    background-color: $primary;
    border-radius: 100%;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: $primary;
}

.date-range-picker {
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: rgba($primary, 0.7);
        border-radius: 0;
    }
    .DayPicker-Day--start:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        color: #f0f8ff;
        background-color: $primary;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
    }
    .DayPicker-Day--end:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        color: #f0f8ff;
        background-color: $primary;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
    }

    input {
        max-width: 150px;
    }
}
