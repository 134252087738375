ul {
    padding: 0;
}

.divider {
    display: block;
    height: 1px;
    width: 100%;
    background: $border-color;
}

.modal-backdrop.show {
    opacity: 0.65;
}

button:not(disabled) {
    cursor: pointer;
}

.thumbnail {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bordered-list {
    list-style-type: none;

    li {
        padding: $padding-s 0;

        &:first-child {
            padding-top: 0 !important;
        }
        &:last-child {
            padding-bottom: 0 !important;
        }
    }

    li ~ li {
        border-top: 1px solid $border-color;
    }

    a {
        color: $body-color;

        .icon {
            fill: $body-color-light;
            width: 16px;
            height: 16px;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

.full-width {
    width: 100%;
}
