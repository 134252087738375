.badge {
    padding: 0.3rem 0.9rem;
    user-select: none;

    margin-right: $padding-xs;

    &.badge-light {
        background: #e7e7e7;
        color: $body-color;
        margin-bottom: $padding-xs;
        @include font-size(14);
    }

    &.badge-border {
        background: transparent;
        color: $body-color;
        border: 1px solid $border-color;
        @include font-size(14);
    }
}
