.icon {
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    svg {
        width: 100%;
        height: 100%;
        fill: $body-color;
    }

    &.icon-xxl {
        width: 84px;
        height: 84px;
    }

    &.icon-xl {
        width: 60px;
        height: 60px;
    }

    &.icon-lg {
        width: 35px;
        height: 35px;
    }

    &.icon-md {
        width: 20px;
        height: 20px;
    }
    &.icon-sm {
        width: 18px;
        height: 18px;
    }
    &.icon-xs {
        width: 16px;
        height: 16px;
    }

    &.icon-light {
        fill: $body-color-light;
    }

    &.icon-lighter {
        fill: $body-color-light;
        opacity: 0.5;
    }
    &.icon-alert {
        fill: $alert-color;
    }
    &.icon-success {
        fill: $success-color;
    }
}

.icon-circle {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    text-align: center;
    background-color: $body-bg;
    border: 2px solid #e7e7e7;

    .icon {
        width: 18px;
        height: 18px;
    }
    &.icon-success {
        background-color: $primary;
        border-color: $primary;
        .icon {
            fill: $white;
        }
    }
    &.icon-error {
        background-color: $alert-color;
        border-color: $alert-color;
        .icon {
            fill: $white;
        }
    }
}
