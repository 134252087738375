.form-check-group {
    padding: $padding-s;
    border: 1px solid $border-color;
    border-radius: $border-radius;

    &.is-invalid {
        border: 1px solid $alert-color;
    }
}

.form-check {
    cursor: pointer;

    > * {
        cursor: pointer;
    }

    label {
        color: $body-color;
    }
}

.form-control:not(textarea) {
    height: auto !important;
}

.form-datetime {
    display: flex;
    flex-wrap: wrap;
    margin-top: -$padding-sm;

    & > * {
        margin-top: $padding-sm;
    }

    .DayPickerInput {
        flex-grow: 1;
        margin-right: $padding-m;
    }
    select.form-control {
        width: auto;
        min-width: 80px;
    }
}

input {
    &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: $body-color-light !important;
        opacity: 0.5 !important;
    }
    &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: $body-color-light !important;
        opacity: 0.5 !important;
    }
    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: $body-color-light !important;
        opacity: 0.5 !important;
    }
    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $body-color-light !important;
        opacity: 0.5 !important;
    }
}

form {
    select {
        &.form-control {
            appearance: none;
            background: $input-bg;
            background-image: url('../../../assets/triangle-select.png');
            background-repeat: no-repeat;
            background-position: right center;
            background-size: 22px;
            cursor: pointer;
            @extend .form-control;
        }
    }
    select::-ms-expand {
        display: none;
    }
    .form-group-title {
        font-weight: $font-weight-semi-bold;
    }
    .form-child-group {
        border: 1px solid $border-color;
        padding: $padding-m;
        border-radius: $border-radius-sm;

        .form-group-title {
            font-weight: $font-weight-normal;
            color: $body-color-light;
        }
    }
    .input-group-text {
        background: darken($input-bg, 2);
        user-select: none;
    }
    .d-flex > .form-check {
        margin: 0;
        padding: $padding-s 0;
        margin-right: $padding-l;
        label {
            margin: 0;
        }
    }
    input[type='radio'] {
        position: relative;
        top: 1px;
        margin-right: $padding-s;
    }
    .asterisk {
        color: $body-color;
        opacity: 0.5;
        @include font-size(14);
    }
    .invalid-feedback {
        display: block;
    }

    &.form-filter {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        input,
        select {
            padding: $padding-xs $padding-m $padding-xs $padding-m;
            border-radius: $border-radius-xl;
            border: 1px solid $border-color;
            background-color: $white;
            background-position: 98% center;
            height: 40px;
        }

        select {
            padding-right: $padding-xl;
            max-width: 220px;
        }
    }

    .input-group {
        .input-group-addon {
            position: absolute;
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: $padding-sm;
            svg {
                fill: white;
            }
        }

        input {
            @include media-breakpoint-down(xs) {
                cursor: pointer;
            }
        }

        & > .form-control:not(:first-child) {
            border-top-left-radius: $border-radius-xxl;
            border-bottom-left-radius: $border-radius-xxl;
        }
    }
    .img-preview {
        max-width: 150px;
        margin-top: $padding-s;
    }
}

.upload-results {
    p,
    img {
        padding: 0.5rem 1rem;
        border-left: 1px $border-color solid;
        color: $body-color-light;
        margin: 1rem 0;
    }
    .btn-icon {
        .icon {
            width: 20px;
            height: 20px;
        }
    }
}
