.sidebar {
    position: fixed;
    left: 0;
    bottom: 0;
    top: $navbar-default-height;
    width: $sidebar-default-width;
    height: 100%;
    background: $white;
    box-shadow: 2px 0 4px rgba(#000, 0.1);
    z-index: 1;

    ul {
        margin-top: $padding-s;
    }

    li {
        list-style-type: none;

        a {
            display: block;
            text-align: center;
            padding: $padding-m + $padding-xs $padding-m + $padding-xs;
            border-left: 2px solid $white;
            transition: all 0.2s ease-out;

            .label {
                display: block;
                font: inherit;
                color: $body-color-light;
                transition: all 0.2s ease-out;
                @include font-size(14);
            }

            .icon {
                width: 40px;
                height: 40px;
                margin: 0 auto;
                fill: $body-color-light;
            }

            &:hover {
                text-decoration: none;
                .label {
                    color: darken($body-color-light, 10);
                }
            }

            &.active {
                border-color: $primary;

                .label {
                    color: $primary;
                    font-weight: $font-weight-semi-bold;
                }

                .icon {
                    fill: $primary;
                }
            }
        }
    }
}

// Mobile sidebar
@include media-breakpoint-down(md) {
    .sidebar {
        top: 0;
        bottom: auto;
        position: relative;
        width: auto;
        height: auto;

        ul {
            display: flex;
            justify-content: space-around;
            margin: 0;
        }
        li {
            flex-grow: 1;
            a {
                padding: $padding-xs + $padding-s;
                border-left: none;
                border-bottom: 2px solid $white;

                .label {
                    @include media-breakpoint-down(xs) {display: none;}
                }
                .icon {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}
