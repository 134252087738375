.content-header {
    width: 100%;
    background: $white;
    padding: $padding-l $padding-m;

    h1 {
        margin: 0;
    }

    .btn-back + * {
        margin-top: $padding-s;
    }
}

.content-body {
    padding: $padding-xl $padding-m;
}

// Tablet and Phone app-content
@include media-breakpoint-up(sm) {
    .content-header {
        padding: $padding-l $padding-l;
    }
    .content-body {
        padding: $padding-l $padding-l;
    }
}

// Desktop app-content
@include media-breakpoint-up(lg) {
    .content-header {
        padding: $padding-l $padding-xxl $padding-m $padding-xxl;
    }
    .content-body {
        padding: $padding-xl $padding-xxl;
    }

    .no-sidebar {
        .content-body {
            margin: 0 auto;
            max-width: 1000px;
        }
    }
}
