.navbar-main {
    background: $primary-dark;
    z-index: 2;
    padding: 0 $padding-sm;
    min-height: 70px;

    .logo {
        width: 105px;

        img {
            height: auto;
        }
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 1px;
        background: rgba(#fff, 0.1);
    }

    .nav-item-user {
        .dropdown-menu {
            position: absolute;
            overflow-x: hidden;
        }

        .dropdown-toggle {
            display: inline-flex;
            align-items: center;
            background: none;
            outline: none;
            border: none;
            color: rgba(#fff, 0.9);

            .icon {
                width: 22px;
                height: 22px;
                fill: $white;
                margin-right: $padding-xs;
            }
        }
    }
}

// Mobile header
@include media-breakpoint-down(sm) {
    .navbar-main {
        .dropdown-toggle {
            span {
                display: none;
            }
        }

        .nav-item-user {
            .dropdown-menu {
                left: -101px;
                top: 55px;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .navbar-main {
        .dropdown-menu {
            width: calc(100% - #{$dropdown-mobile-margin});
            left: $dropdown-mobile-margin / 2;
            right: $dropdown-mobile-margin / 2;
        }
        .form-search {
            input {
                width: 0;
                padding: $padding-s $padding-l;
                background-color: transparent;

                &.active {
                    position: fixed;
                    left: 0;
                    top: 3px;
                    height: 50px !important;
                    width: calc(100% - 16px);
                    background-color: $input-background-color;
                    z-index: 1;
                    margin: $padding-s;
                }
            }
            .dropdown-menu {
                &.show {
                    position: fixed;
                    top: $mobile-navbar-dropdown-height;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .navbar-main {
        .form-search {
            input {
                padding-left: $padding-xxl;
            }
        }
    }
}

// Special media query for small phones such as iPhone SE
@media (max-width: '340px') {
    .navbar-main {
        .navbar-brand {
            margin-right: 0;
        }
        .input-group-addon {
            padding-left: 5px;
        }
        .navbar-nav {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
}
