.app-footer {
    padding: $padding-m;
    position: static;
    width: 100%;
    text-align: center;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $body-color-light;
        .icon {
            fill: $body-color-light;
            width: 18px;
            height: 18px;
            margin-right: $padding-xs;
        }
        &:hover {
            color: $body-color;
            border: none;
            text-decoration: none;
            .icon {
                fill: $body-color;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .app-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: $sidebar-default-width;
        z-index: 1000;
    }
}
