$font-family-base: 'Open Sans', sans-serif;
$font-weight-normal: 400 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;

$font-family-serif: 'Playfair Display', serif;
$font-weight-serif-normal: 700;

$body-bg: #f7f8f9;

$card-hover-bg: #fafafa;
$card-max-width: 540px;

$border-color: rgba(#97a49d, 0.2);

$body-color: #4b5650;
$body-color-light: #94a29b;

$alert-color: #f6051e;
$success-color: #3ac179;
$transparent-alert-color: rgba(rgb(0, 0, 0), 0.8);
$accent-color: #f5a302;

$btn-border-radius: 4px;

$navbar-default-height: 70px;
$mobile-navbar-dropdown-height: 55px;

$badge-font-size: 0.6875rem;
$badge-font-weight: $font-weight-normal;
$badge-padding-y: 0.6em;
$badge-padding-x: 1.1em;
$badge-border-radius: 32px;

$dropdown-link-active-bg: #eee;
$dropdown-divider-bg: $border-color;
$dropdown-mobile-margin: 48px;

$h1-font-size: 2rem;
$h2-font-size: 0.875rem;
$h3-font-size: 1.125rem;

$border-radius-xl: 18px;
$border-radius-xxl: 22px;

$sidebar-default-width: 160px;
$sidebar-sm-height: 77px;
$sidebar-xs-height: 56px;

$input-disabled-bg: #fff;
$input-bg: #fafafa;
$input-border-color: $border-color;
$input-border-radius: 4px;
$input-focus-border-color: darken($border-color, 10);
$input-btn-focus-box-shadow: 0 0 3px rgba(#000, 0.1);

$input-placeholder-radius: $body-color-light;
$input-background-color: #3ac179;

$graph-min-height: 350px;
$graph-max-height: 550px;
$graph-min-width: 600px;

$padding-xs: 4px;
$padding-s: 8px;
$padding-sm: 14px;
$padding-m: 16px;
$padding-ml: 18px;
$padding-l: 24px;
$padding-xl: 32px;
$padding-xxl: 48px;
$padding-xxxl: 64px;

// Extra grid
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1354px
        // Sould be 1366 but windows scrollbar takes up part of the screen.
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1300px
);

$modal-xl: 1140px !default;
$modal-xxl: 1300px !default;
