.loading-indicator {
    margin: $padding-m auto;
    width: 24px;
    height: 24px;
    position: relative;
}
.loading-indicator .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.loading-indicator .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: $body-color-light;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 0.6s infinite ease-in-out both;
    animation: sk-circleBounceDelay 0.6s infinite ease-in-out both;
}
.loading-indicator .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg); }
.loading-indicator .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg); }
.loading-indicator .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }
.loading-indicator .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg); }
.loading-indicator .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg); }
.loading-indicator .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
.loading-indicator .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg); }
.loading-indicator .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg); }
.loading-indicator .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg); }
.loading-indicator .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg); }
.loading-indicator .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg); }
.loading-indicator .sk-circle2:before {
    -webkit-animation-delay: -0.55s;
    animation-delay: -0.55s; }
.loading-indicator .sk-circle3:before {
    -webkit-animation-delay: -0.50s;
    animation-delay: -0.50s; }
.loading-indicator .sk-circle4:before {
    -webkit-animation-delay: -0.45s;
    animation-delay: -0.45s; }
.loading-indicator .sk-circle5:before {
    -webkit-animation-delay: -0.40s;
    animation-delay: -0.40s; }
.loading-indicator .sk-circle6:before {
    -webkit-animation-delay: -035s;
    animation-delay: -0.35s; }
.loading-indicator .sk-circle7:before {
    -webkit-animation-delay: -0.30s;
    animation-delay: -0.30s; }
.loading-indicator .sk-circle8:before {
    -webkit-animation-delay: -0.25s;
    animation-delay: -0.25s; }
.loading-indicator .sk-circle9:before {
    -webkit-animation-delay: -0.20s;
    animation-delay: -0.20s; }
.loading-indicator .sk-circle10:before {
    -webkit-animation-delay: -0.15s;
    animation-delay: -0.15s; }
.loading-indicator .sk-circle11:before {
    -webkit-animation-delay: -0.10s;
    animation-delay: -0.10s; }
.loading-indicator .sk-circle12:before {
    -webkit-animation-delay: -0.05s;
    animation-delay: -0.05s; }

@-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
}

@keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
}