.impersonation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin: 0;

    z-index: 10;
    position: sticky;
    top: 0;
}
