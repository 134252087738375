.btn-to {
    display: inline-flex;
    transition: color 0.2s ease-out;
    color: $body-color-light;
    text-decoration: none;
    user-select: none;
    align-items: center;
    @include font-size(14);

    .icon {
        width: 14px;
        height: 14px;
        margin-right: $padding-xs;
        fill: $body-color-light;
    }

    &.btn-back {
        color: lighten($body-color-light, 3);
        .icon {
            fill: lighten($body-color-light, 3);
        }
    }

    span {
        font: inherit;
        color: inherit;
    }

    &:hover {
        color: darken($body-color-light, 10);
        text-decoration: none;

        .icon {
            fill: darken($body-color-light, 10);
        }
    }
}

.btn-primary,
.btn-secondary {
    display: inline-flex;
    font-weight: $font-weight-semi-bold;
    color: $white;
    border: none;
    padding: 0.6rem 1.8rem;
    align-items: center;

    .icon {
        width: 20px;
        height: 20px;
        fill: $white;
        margin-left: $padding-s;
    }

    &.btn-submit {
        padding: 0.6rem 1.8rem 0.6rem 2.4rem;
    }

    &.btn-round {
        border-radius: 32px;
        font-weight: $font-weight-normal;
        line-height: 1rem;
        padding: 0.35rem 1.2rem 0.35rem 0.9rem;

        .icon {
            width: 24px;
            height: 24px;
            margin-left: 0;
            margin-right: $padding-s;
            position: relative;
            top: 1px;
        }
    }
}

.btn-group-toggle {
    .btn-secondary {
        font-weight: $font-weight-normal;
        background: white;
        color: $body-color-light;
        border: 1px solid $body-color-light !important;
        min-width: 20%;
        text-align: center;
        justify-content: center;
        &:hover {
            background: lighten($secondary, 4) !important;
            border-color: darken($secondary, 0) !important;
            color: white;
        }
        &.active {
            color: white;
            background: lighten($secondary, 1) !important;
            border-color: darken($secondary, 0) !important;
            font-weight: $font-weight-bold;
        }
    }
}

.btn-primary {
    background: $primary-dark;

    &[disabled] {
        opacity: 1;
        background: rgba(darken($primary-dark, 4), 0.4);
        cursor: default;
    }
}

.btn-alert {
    color: $alert-color !important;

    .icon {
        fill: $alert-color !important;
    }
}

.btn-success {
    color: $success-color !important;

    &:hover,
    &:active,
    &:focus {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
    }
    .icon {
        fill: $success-color !important;
    }
}

.btn-link {
    @include font-size(14);
    outline: none;
    border: none;

    &.btn-cancel {
        color: $body-color-light;
        text-decoration: underline;
    }
    &.btn-light {
        color: $body-color-light;
        text-decoration: underline;
        background: transparent !important;
    }
}

.btn-description {
    background: transparent;
    transition: background 0.2s ease-out;
    @include font-size(14);
    outline: none;
    border: none;
    padding: $padding-s $padding-s;

    &:hover {
        background: $card-hover-bg;
        border-radius: 4px;
        color: inherit;
    }

    &:focus {
        color: inherit;
    }
}

.btn-card {
    background: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s ease-out;
    padding: $padding-s + $padding-xs $padding-m;
    @include font-size(15);

    &:active {
        color: inherit;
    }

    .icon {
        width: 20px;
        height: 20px;
        margin-right: $padding-s;
        fill: $body-color-light;
    }

    &:hover {
        background: $card-hover-bg;
    }
}

.btn-icon {
    display: flex;
    padding: 0;
    background: transparent;
    border: none;
    min-width: 24px;
    height: 24px;
    color: $body-color-light;
    @include font-size(14);

    &:not(.disabled):not(disabled) {
        cursor: pointer;
    }

    &.disabled,
    &:disabled {
        cursor: default;
    }

    .icon {
        fill: $body-color-light;
        transition: fill 0.2s ease-out;
        cursor: pointer;
    }

    &:hover:not(.disabled):not(disabled) {
        .icon {
            fill: darken($body-color-light, 10);
        }
    }
}

.btn {
    .loading-indicator {
        width: 18px;
        height: 18px;
        margin: 0;

        .sk-child:before {
            background-color: white;
        }
    }
}

.btn-div {
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    user-select: none;

    &[disabled],
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed !important;
        &:hover,
        &:focus,
        &:active {
            cursor: not-allowed !important;
        }
    }

    &:active {
        color: inherit;
    }
}

.btn-tertiary {
    background-color: transparent;
    border: 2px $border-color solid;
    font-weight: 600;
    text-align: center;
    color: $primary;
    &:hover {
        color: $primary !important;
        border-color: darken($border-color, 50);
    }
}
