/* Variables and brand */
@import 'mixins';
@import 'brand';
@import 'variables';

/* Libraries */
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/react-day-picker/lib/style';

/* Custom styling */
@import './base/typography';
@import './base/generic';
@import './base/badge';
@import '../components/shared/forms/forms';
@import '../components/shared/table/table';
@import '../components/onboarding/onboarding';
@import '../components/onboarding/mobile/mobile_app';
@import '../components/app/app';
@import '../components/clients/search/search_form';
@import '../components/impersonation/impersonation';
@import '../components/shared/icon/icon';
@import '../components/shared/header/header';
@import '../components/shared/footer/footer';
@import '../components/shared/sidebar/sidebar';
@import '../components/shared/subscription/subscription_status';
@import '../components/shared/content/content';
@import '../components/shared/dropdown/dropdown';
@import '../components/shared/progress/progressbar';
@import '../components/shared/read/read_notification';
@import '../components/shared/tabs/tabs';
@import '../components/shared/buttons/buttons';
@import '../components/shared/loading/loading-icon';
@import '../components/shared/card/card';
@import '../components/shared/modal/modal';
@import '../components/shared/datepicker/datepicker';
@import '../components/shared/pagination/pagination';
@import '../components/shared/message/message-bar';
@import '../components/clients/detail/evolution/graph/graph';

@import '../components/clients/clients';
