.message-bar {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 24px;
    z-index: 100;
    align-items: center;
    justify-content: center;

    p {
        margin: 0;
        color: white;
        text-align: center;
        font-weight: $font-weight-semi-bold;
        opacity: 0;
        padding: $padding-s $padding-m;
        border-radius: 32px;
        background: rgba(#000, 0.8);
        transform: translateY(100px);
        transition: all 0.2s ease-out;
        min-width: 160px;
    }

    &.visible {
        p {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &.message-bar-alert {
        p {
            background: $alert-color;
        }
    }
}
