.read {
    position: relative;
    bottom: 1px;
    display: inline-block;
    margin-right: $padding-sm;
    width: $padding-s;
    height: $padding-s;
    border-radius: $padding-s;
    background-color: $accent-color;
}

.read-th {
    padding: 0;
}
.read-td {
    padding-left: 0;
    padding-right: 0;
    .read {
        margin-left: $padding-sm;
        margin-right: 0;
    }
}
