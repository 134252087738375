.card {
    position: relative;
    border: none;
    box-shadow: 0 1px 2px rgba(#000, 0.04);

    .card-buttons {
        position: absolute;
        top: $padding-s;
        right: $padding-m;
    }

    > .alert-danger {
        margin: $padding-m;
    }

    &.card-bordered {
        border: 1px solid $border-color;
        box-shadow: none;
    }

    &.card-selectable {
        transition: all 0.2s ease-out;
        &:hover {
            cursor: pointer;
            background: $card-hover-bg;
        }

        &.active {
            border-color: $primary;
            &:hover {
                cursor: default;
            }
        }
    }

    &.disabled {
        background: repeating-linear-gradient(
            45deg,
            #fff,
            #fff 94%,
            #e7e7e7 94%,
            #e7e7e7 100%
        );
    }

    .img-preview {
        max-width: 150px;
        margin: $padding-s 0 $padding-m 0;
    }
}
