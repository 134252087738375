.app {
    height: 100vh;
    // width: 100vw;
}
.app-container {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: $navbar-default-height;
    bottom: 0;
}

@include media-breakpoint-down(md) {
    .app-container {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
}

.app-content {
    top: $navbar-default-height;
    &.no-sidebar {
        margin-top: $navbar-default-height;
    }
}

// Tablet and phones app-content
// @include media-breakpoint-up(sm) {
//     .app-content {
//         top: $sidebar-sm-height;
//     }
// }

// Desktop app-content
@include media-breakpoint-up(lg) {
    .app-content {
        position: fixed;
        top: $navbar-default-height;
        right: 0;
        bottom: 0;
        left: $sidebar-default-width;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        &.no-sidebar {
            left: 0;
            right: 0;
            margin-top: 0;
        }
    }
}
