.tabs {
    .nav-pills {
        width: 100%;
        li {
            margin-top: $padding-xs;
            margin-bottom: $padding-xs;
        }
        .nav-link {
            background: transparent;
            color: $body-color-light;
            padding: 0.3rem 0.9rem;
            border-radius: 24px;
            @include line-height(16);
            user-select: none;
            transition: all 0.2s ease-out;

            &:hover {
                color: darken($body-color-light, 10);
            }

            &.active {
                background: $primary;
                color: $white;
            }
        }
    }

    * + & {
        margin-top: $padding-m;
    }
}
