.dropdown-toggle {
    cursor: pointer;
}

button.dropdown-toggle:not(.nav-link) {
    &::after{
        content: none;
    }
}
.delete-toggle {
    height: 30px;
}

.dropdown-menu {
    box-shadow: 0 2px 4px rgba(#333, 0.05);

    max-height: 200px;
    overflow-y: auto;

    top: 80%;

    &.bottom {
        top: auto;
        bottom: 80%;
    }
    &.top {
        top: 80%;
        bottom: auto;
    }
    &.right {
        right: 20%;
        left: auto;
    }
    &.left {
        left: 20%;
        right: auto;
    }
}

.dropdown-item {
    display: flex;
    padding: 0.25rem 1rem;

    .icon {
        margin-right: $padding-s;
    }

    &.active-item {
        background-color: $body-color;
    }

    &:hover,
    &:focus,
    &:active {
        color: inherit;
    }
}
