.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-top: $padding-s;
    padding: $padding-s 0;

    .page-item {
        border: none;
        margin: 0 $padding-xs;

        &.page-item-page {
            margin: 0;
        }

        &.page-item-page + .page-item-button {
            margin-left: $padding-s;
        }

        &.page-item-button + .page-item-page {
            margin-left: $padding-s;
        }
    }

    .page-item-button {
        &.disabled {
            opacity: 0.3;

            &:hover {
                cursor: default;
            }
        }
    }

    .page-item-page {
        button {
            color: $body-color-light;
        }

        &.active {
            button {
                color: $body-color;
                font-weight: $font-weight-semi-bold;
            }
        }
    }

    .btn-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            width: 20px;
            height: 20px;
            fill: $body-color-light;
        }
    }

    .btn-page {
        background: none;
        padding-left: $padding-xs;
        padding-right: $padding-xs;
        color: $body-color-light;
    }
}