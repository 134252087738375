html,
body {
    font-size: 0.9375rem;
}

h1 {
    color: $primary;
    font-family: $font-family-serif;
    font-weight: $font-weight-serif-normal;
    user-select: none;
    @include font-size(26);

    & + p {
        margin-top: $padding-s;
    }
}

h2 {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: $font-weight-semi-bold;
    color: $body-color-light;
    @include font-size(14);
    margin-bottom: ($spacer * 0.5);

    padding-left: 4px;

    .icon {
        width: 20px;
        height: 20px;
        margin-right: $padding-xs;
        fill: $body-color-light;
    }
}

h3 {
    color: $primary;
    font-weight: $font-weight-bold;
    @include font-size(18);

    .extra-info {
        font: inherit;
        font-weight: $font-weight-normal;
        color: $body-color-light;
        @include font-size(15);
    }
}

h4 {
    color: $secondary;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-light;
    user-select: none;
    @include font-size(38);
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

label,
.label,
dt {
    color: $body-color-light;
    font-weight: $font-weight-normal;
}

.row {
    > dt,
    > dd {
        @include line-height(18);
        margin-bottom: 0.8rem;
        padding-bottom: $padding-xs;
    }
}

.font-weight-semi-bold {
    font-weight: $font-weight-semi-bold;
}

.color-alert {
    color: $alert-color;
}

.color-secondary {
    color: $secondary;
}

.alert {
    &.alert-empty {
        color: $body-color-light;
        margin-bottom: $padding-s;
        font-style: italic;
    }
    &.alert-danger {
        font-style: italic;
        h3 {
            color: #721c24;
            font-style: normal;
        }
    }
    &.alert-warning {
        a,
        button {
            color: inherit;
            text-decoration: underline;
            &:hover {
                color: inherit;
            }
        }
    }
}

.text-body {
    color: $body-color;
}
.text-body-light {
    color: $body-color-light;
}

.font-size-extra-small {
    @include font-size(11);
}
.font-size-small {
    @include font-size(13);
}
.font-size-large {
    @include font-size(30);
}
