@mixin column-percent($percent) {
    &.column-#{$percent} {
        width: #{$percent}% !important;
        min-width: #{$percent}% !important;
    }
}

table {
    width: 100%;

    &.table-fixed {
        table-layout: fixed;
    }

    th,
    td {
        padding: $padding-s + $padding-xs $padding-m;

        @include column-percent(10);
        @include column-percent(20);
        @include column-percent(30);
        @include column-percent(40);
        @include column-percent(50);
        @include column-percent(60);
        @include column-percent(70);
        @include column-percent(80);
        @include column-percent(90);
        @include column-percent(100);
    }

    .font-weight-semi-bold {
        color: $body-color;
    }

    thead {
        tr {
            border-bottom: 1px solid $border-color;
        }
    }

    th {
        font-weight: $font-weight-semi-bold;
    }

    td {
        color: $body-color-light;
        vertical-align: top;
        a,
        .font-weight-semi-bold {
            color: $body-color !important;
        }
    }

    tbody {
        tr {
            transition: background 0.1s ease-out;
            &:nth-child(odd) {
                background: rgba(#f8f8f8, 0.6);
            }
            &.tr-selectable {
                cursor: pointer;
                &:hover {
                    background: rgba(#f8f8f8, 0.9);
                }
            }
        }
    }

    &.table-sortable {
        user-select: none;

        th {
            &.sortable {
                cursor: pointer;
                transition: background 0.1s ease-out;

                &:hover {
                    background: rgba(#000, 0.05);
                }
                &.active {
                    color: $primary-dark;
                }
            }

            .sort-icon {
                margin-left: $padding-s;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .icon {
                    fill: $body-color-light;
                    width: 14px;
                    height: 14px;
                    min-width: 16px;
                    opacity: 0.8;

                    &:first-child {
                        margin-bottom: -4px;
                    }

                    &:last-child {
                        margin-top: -8px;
                    }

                    &.active {
                        fill: $primary;
                        opacity: 1;
                    }
                }
            }
        }

        &.disabled {
            opacity: 0.7;
        }
    }
}

.card-body {
    .table-responsive {
        position: relative;
        left: -1.25rem;
        width: calc(100% + 2.5rem);
        margin-top: -1.25rem;
        margin-bottom: -1.25rem;

        overflow-y: auto;
        max-height: 300px;

        thead tr {
            border: 0;
        }
        thead th {
            position: sticky;
            top: 0;
            background-color: white;
            box-shadow: 0px 1px rgba(151, 164, 157, 0.2);
        }
    }
}
